<template>
  <v-layout id="builder" align-start row >

    <main-nav></main-nav>
    <setup v-if="viewSetup"></setup>



  </v-layout>
</template>

<script>
// @ is an alias to /src
const player = () => import("@/components/player.vue");
const setup = () => import("@/components/setup.vue");
const mainNav = () => import("@/components/shared/nav.vue");

export default {
  name: "builder",
  props: ['user'],
  components: {
    player, mainNav, setup,
  },
  data(){
    return{
      viewSetup: true,
    }
  },
  methods: {

  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#builder{

}

</style>
